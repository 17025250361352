:root {
  --highlight-red: #fa8072;
  --highlight-green: #9acd32;
  --highlight-purple: #9a32cd;
  --default-green: #0f9668;
  --default-blue: #1890ff;
  --default-red: #fa4d4f;
  --default-highlight: var(--highlight-red);
  --default-white: white;
  --light-background-color: #fafafa;
  --default-background-color: #242730;
  --secondary-background-color: #29323c;
  --default-hover-color: #9199a1;
  --default-grey: rgb(106, 116, 133);
  --secondary-grey: rgb(147, 155, 170);
  --shade: rgb(58, 69, 82, 0.5);
  --light-shade: rgb(106 116 133/35%);
  --light-text: rgba(255, 255, 255, 0.65);
}

.default-btn {
  background-color: var(--default-grey) !important;
  color: var(--default-white) !important;
}

.default-run-senario-btn {
  background-color: #44946c !important;
  color: var(--default-white) !important;
}

.default-text {
  color: var(--default-white) !important;
  margin: 0px !important;
}

.secondary-text {
  color: var(--default-grey) !important;
  margin: 0px !important;
}

.controll .ant-typography {
  transition: 0.5s ease-out;
}

.controll h5.ant-typography {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1.8;
  color: #f3ececd9 !important;
  letter-spacing: 0.15px;
  font-weight: 400;
}

.controll .ant-card-body:hover .ant-typography {
  transition: 0.2s ease-in;
  color: #fff !important;
}

.controll svg {
  transition: 0.5s ease-out;
}

.controll svg:hover:not(.bar-plot svg) {
  color: aliceblue;
  transition: 0.2s ease-in;
  transform: scale(1.1);
}

.controll button:not(.layer button) {
  border: 0;
}
.controll button:active:not(.layer button) {
  transform: scale(0.95);
}

.controll .ant-tabs-nav-operations {
  display: none !important;
}

.controll .ant-tabs-content-holder:not(.category-tab .ant-tabs-content-holder) {
  padding: 24px;
  /* overflow-y: scroll !important; */
  width: 100% !important;
}

/* .layer {
  margin-bottom: 12px;
} */

.map-tools-icon {
  outline: none !important;
  border: 0 !important;
}
.map-tools-icon:active {
  transform: scale(0.95);
}

.expand-controls {
  position: absolute !important;
  top: 16px;
  right: 10px;
  outline: none !important;
  border: 0 !important;
}

.expand-controls:active {
  transform: scale(0.95);
}

.map-tools {
  position: absolute !important;
  top: 50px;
  left: 10px;
}

.map-tools .ant-space-item {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}

.map-tools .ant-popover-inner-content {
  padding: 0px !important;
}

.time-series.ant-card {
  background: transparent !important;
}

.time-series .ant-card-head-title,
.time-series .ant-card-extra {
  padding: 0px !important;
}

.time-series .ant-card-head {
  border: none !important;
}

.time-series .ant-card-body {
  background-color: rgba(41, 50, 60, 0.25) !important;
}

.time-series .ant-slider-handle-1::after {
  content: "";
  position: absolute;
  top: -65px;
  left: 5px;
  height: 35px;
  width: 4px;
  background-color: aliceblue;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: ew-resize;
}

.time-series .ant-slider-track-1::before {
  content: "";
  position: absolute;
  top: -90px;
  height: 75px;
  width: 100%;
  background-color: var(--default-grey);
  opacity: 0.15;
  border-radius: 5px;
  cursor: move;
}

.time-series .ant-slider-handle-2::before {
  content: "";
  position: absolute;
  top: -65px;
  right: 5px;
  height: 35px;
  width: 4px;
  background-color: aliceblue;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: ew-resize;
}

.time-series-info {
  position: absolute !important;
  bottom: 16px;
  left: 13%;
  transform: translateX(-5%);
  background-color: var(--default-background-color) !important;
  width: 55%;
  height: 250px;
  padding: 12px;
}

.time-series-info .ant-page-header-heading {
  display: flex;
  align-items: center;
}

.time-series-info .ant-page-header-content {
  height: 160px !important;
  display: flex;
  align-items: flex-end !important;
}

.time-series-slider .ant-dropdown-menu-item:hover {
  background: none;
}

.time-series-graph {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 58px;
}

.time-series-graph .ant-slider-handle-1::after {
  content: "";
  position: absolute;
  top: -80px;
  left: 5px;
  height: 45px;
  width: 4px;
  background-color: aliceblue !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: ew-resize;
}

.time-series-graph .ant-slider-track-1::before {
  content: "";
  position: absolute;
  top: -115px;
  height: 102px;
  width: 100%;
  background-color: var(--default-grey);
  opacity: 0.25;
  border-radius: 5px;
  cursor: move;
}

.time-series-graph .ant-slider-handle-2::before {
  content: "";
  position: absolute;
  top: -80px;
  right: 5px;
  height: 45px;
  width: 4px;
  background-color: aliceblue !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: ew-resize;
}

.time-series-graph .rv-xy-plot__grid-lines__line {
  /* stroke: var(--default-blue); */
  stroke-dasharray: 1px 4px;
}

.time-series-graph .rv-xy-plot__axis--horizontal {
  transform: translateY(115px);
}

.time-series-graph .rv-xy-plot__axis__tick__text {
  font-size: 9px;
  fill: var(--default-hover-color) !important;
}

.time-series-graph .rv-xy-plot__inner {
  /* important to show axis */
  overflow: visible;
}

.time-series-tools {
  width: 100%;
  justify-content: flex-end;
}

.map-styles-collapse > .ant-collapse-header {
  height: 50px;
  padding: 2px 16px !important;
  background-color: var(--secondary-background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-styles-collapse > .ant-collapse-header:hover {
  background: var(--shade);
}

.map-styles-collapse .ant-typography {
  float: left;
  margin-top: 8px;
  color: var(--default-grey);
}

.map-styles-collapse svg {
  color: var(--default-grey);
  font-size: 14px;
}

.map-styles-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
  margin: 8px 0px;
}

.map-styles-collapse .ant-page-header {
  padding: 0px 16px;
  background-color: var(--secondary-background-color);
  cursor: pointer;
}
.map-styles-collapse .ant-page-header:hover {
  background: var(--shade);
}

.map-styles-collapse .ant-page-header-heading .ant-avatar {
  border: 1px solid var(--default-grey);
}

.sorting-layer {
  z-index: 10000 !important;
}

.layer > .ant-collapse-header {
  height: 52px;
  align-items: center !important;
  justify-content: space-between;
  border-radius: 2px !important;
  padding: 8 !important;
  background-color: var(--secondary-background-color);
}

.layer .ant-collapse-content > .ant-collapse-content-box {
  padding: 8px !important;
}

.layer > .ant-collapse-header:hover {
  background: var(--shade);
}

.layer .ant-typography {
  margin-top: 8px;
  color: var(--default-grey);
}

.layer svg {
  font-size: 12px;
}

.layer .ant-collapse-arrow {
  color: #efe6e6;
}

.layer .ant-collapse-extra {
  margin: 0 !important;
}

.layer-properties-overlay::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.layer-properties-overlay {
  opacity: 0.5;
}

.layer-properties {
  position: relative;
}

.layer-properties .ant-collapse-header {
  padding: 0 !important;
  cursor: pointer !important;
  width: 100% !important;
}

.layer-properties .ant-collapse-header-text {
  width: 100% !important;
}

.layer-properties .ant-collapse-content > .ant-collapse-content-box {
  padding: 12px !important;
}
.layer-properties .config-name {
  /* border-left: 3px solid var(--default-grey) !important; */
  /* padding-left: 5px; */
  color: var(--default-grey) !important;
  font-size: 14px;
  margin: 0;
  line-height: 16px;
  font-family: Inter;
}

.layer-properties .label {
  color: var(--default-grey) !important;
  margin: 0 !important;
}

.layer-properties .ant-switch {
  z-index: 3;
}

.layer-properties .ant-switch,
.analytics .ant-switch,
.color-range-container .ant-switch,
.time-series-info .ant-switch,
.ant-switch-handle::before {
  border-radius: 0px !important;
}

.layer-properties .ant-switch,
.analytics .ant-switch,
.time-series-info .ant-switch,
.color-range-container .ant-switch {
  margin-bottom: 4px !important;
  background-color: var(--secondary-background-color) !important;
}
.layer-properties .ant-switch-checked,
.analytics .ant-switch-checked,
.time-series-info .ant-switch-checked,
.color-range-container .ant-switch-checked {
  background-color: var(--default-grey) !important;
}

.layer-properties .ant-switch-checked:focus,
.analytics .ant-switch-checked:focus,
.time-series-info .ant-switch-checked:focus,
.color-range-container .ant-switch-checked:focus {
  box-shadow: none !important;
}

.analytics .ant-switch-small {
  margin-bottom: 2.5px !important;
}

.color-range-container .ant-switch-small {
  margin: 0 !important;
}

.layer-properties .ant-input-number,
.filters .ant-input-number,
.time-series .ant-input-number {
  background-color: var(--secondary-background-color) !important;
  border: 0px !important;
  color: var(--default-white) !important;
}
.layer-properties .ant-input-number-focused,
.filters .ant-input-number-focused,
.time-series .ant-input-number-focused {
  background: var(--shade) !important;
  box-shadow: none !important;
}

.layer-properties .ant-input-number-handler-wrap,
.filters .ant-input-number-handler-wrap,
.time-series .ant-input-number-handler-wrap {
  display: none !important;
}

.layer-properties svg {
  color: var(--default-grey);
  font-size: 24px;
  cursor: pointer;
}

.layer-properties .more-icon {
  margin-right: -10px;
}

.slider-container > .ant-space-item:first-child,
.select-filter-field > .ant-space-item:first-child,
.timeseries-filter-dropdown > .ant-space-item:first-child {
  width: 100% !important;
}

.layer-properties .ant-slider {
  width: 90% !important;
}

.layer-properties .ant-slider-handle:focus,
.time-series-slider .ant-slider-handle:focus,
.filters .ant-slider-handle:focus,
.time-series .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px var(--light-shade) !important;
}

.layer-properties .ant-slider-step,
.time-series-slider .ant-slider-step,
.time-series .ant-slider-step {
  background-color: var(--secondary-background-color) !important;
  border-radius: 0px !important;
}

.layer-properties .ant-slider-track,
.time-series-slider .ant-slider-track,
.time-series-info .ant-slider-track:not(.time-series-range .ant-slider-track),
.filters .ant-slider-track,
.time-series .ant-slider-track {
  background-color: var(--default-grey);
  border: 0px !important;
  border-radius: 0px !important;
  z-index: 1;
}

.time-series-range .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
  height: 15px;
}

.time-series-range .ant-slider-track {
  background-color: rgb(18, 147, 154) !important;
  border: 0px !important;
  border-radius: 0px !important;
  margin-top: 6px !important;
}

.time-series-range .ant-slider:hover .ant-slider-step {
  background-color: var(--light-shade) !important;
}

.time-series-range .ant-slider-handle {
  margin-top: -1.8px;
  height: 18px;
  width: 12px;
}

.time-series-range .ant-slider-dot-active {
  background-color: rgb(18, 147, 154) !important;
  margin-top: 2px !important;
  height: 15px !important;
}

.time-series-range .ant-slider-rail {
  background-color: var(--default-grey) !important;
  margin-top: 6px !important;
}

.time-series-range .ant-slider-dot {
  background-color: var(--default-white);
  height: 8px;
  border: none;
  width: 2px;
  border-radius: 0px;
  margin-top: 5px;
}

.time-series-range .ant-tooltip-inner {
  padding: 8px 16px !important;
  background-color: var(--light-shade) !important;
}

.time-series-range .ant-tooltip-placement-top .ant-tooltip-arrow-content {
  background-color: var(--default-grey) !important;
}

.layer-properties .ant-slider-handle,
.time-series-slider .ant-slider-handle,
.time-series-info .ant-slider-handle,
.time-series .ant-slider-handle,
.filters .ant-slider-handle {
  border: 0px !important;
  border-radius: 0px !important;
  z-index: 1;
}

.layer-properties .ant-slider:hover .ant-slider-track,
.time-series-slider .ant-slider:hover .ant-slider-track,
.time-series .ant-slider:hover .ant-slider-track,
.filters .ant-slider:hover .ant-slider-track {
  background-color: var(--default-grey);
}

.layer-properties input[type="color"] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: 30px;
  background: none;
}
.category-tab .ant-tabs-tab .ant-tabs-tab-btn,
.category-tab .ant-tabs-tab .ant-tabs-tab-btn button {
  color: var(--default-grey) !important;
}
.category-tab .ant-tabs-tab-active .ant-tabs-tab-btn,
.category-tab .ant-tabs-tab-active .ant-tabs-tab-btn span,
.category-tab .ant-tabs-ink-bar {
  color: var(--default-white) !important;
}

.category-tab .ant-tabs-ink-bar {
  display: none;
}

.category-tab .ant-badge-count {
  background: var(--light-shade) !important;
}

.categories-container .ant-collapse-header {
  background-color: rgba(41, 50, 60, 0.95);
}

.categories-container .ant-collapse-content-box {
  padding: 12px 0px !important;
}

.categories-container svg:not(.dataset-handling-icons svg) {
  color: var(--secondary-grey);
}

.dataset-handling-icons {
  z-index: 20000 !important;
  position: absolute !important;
  right: 40px;
  top: 0px;
  z-index: 100000 !important;
}

.categories {
  background-color: transparent !important;
  font-family: Inter;
}

.categories svg:not(.dataset-handling-icons svg) {
  font-size: 18px;
  color: var(--secondary-grey) !important;
}

.categories .ant-tree-iconEle {
  margin: 2.5px 2.5px 0px 0px !important;
  width: auto !important;
  height: auto !important;
}

.categories .ant-tree-treenode-selected::before {
  background-color: var(--secondary-background-color) !important;
  border-left: 3px solid var(--default-green);
}

.categories .ant-tree-treenode:hover::before {
  background-color: var(--secondary-background-color) !important;
  border-left: 3px solid var(--default-green);
}

.categories .ant-tree-treenode-selected .ant-typography,
.categories .ant-tree-treenode-selected svg:not(.dataset-handling-icons svg) {
  color: var(--default-white) !important;
}

.categories .ant-tree-switcher {
  background: transparent !important;
  /* margin-top: 2.5px !important; */
}

.categories .ant-tree-switcher-icon {
  display: none !important;
}

.ant-tabs-content {
  height: 100% !important;
}

.categories
  .ant-tree-node-content-wrapper:not(.ant-tree-node-content-wrapper-normal) {
  margin-left: -22px !important;
}

.categories .ant-tree-node-content-wrapper {
  display: flex;
  padding: 0 !important;
}

.categories .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid var(--secondary-grey) !important;
}
.categories .ant-tree-switcher-leaf-line::before,
.categories .ant-tree-indent-unit::before {
  border-right: 1px solid var(--secondary-grey) !important;
}

.categories .ant-tree-checkbox {
  position: absolute;
  right: 4px;
  z-index: 2 !important;
}

.categories .ant-tree-checkbox-checked:hover::after {
  border: 0 !important;
}

.categories .ant-tree-checkbox-inner,
.assets .ant-checkbox-inner {
  background-color: var(--secondary-background-color) !important;
  border: 0.5px solid var(--secondary-grey) !important;
}

.categories .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.assets .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-grey) !important;
  border-color: var(--default-grey) !important;
}

.categories-upload .ant-typography {
  color: rgb(147, 155, 170);
  font-size: 14px;
  margin: 0px;
  width: 215px;
}

.categories-upload .ant-typography .percentage-text {
  color: var(--default-green) !important;
  font-size: 12px !important;
}

.categories-upload .ant-page-header-heading {
  flex-wrap: nowrap !important;
  width: 100%;
}

.filters svg:not(.ant-btn svg) {
  transition: 0.5s ease-out;
  color: var(--default-grey);
  cursor: pointer;
}

.filters svg:hover:not(.bar-plot svg) {
  color: aliceblue !important;
  transition: 0.2s ease-in;
  transform: scale(1.1);
}

.filters {
  background-color: rgb(36, 39, 48) !important;
  border-radius: 5px !important;
}

.filters .ant-collapse-header {
  background-color: var(--secondary-background-color) !important;
}

.filters .ant-collapse-content {
  background-color: rgba(41, 50, 60, 0.25) !important;
}

.filters .ant-slider-step {
  background-color: rgba(41, 50, 60, 0.95) !important;
  border-radius: 0px !important;
}

.filters .ant-slider-handle-1::after {
  content: "";
  position: absolute;
  top: -65px;
  left: 5px;
  height: 35px;
  width: 4px;
  background-color: aliceblue;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: ew-resize;
}

.filters .ant-slider-track-1::before {
  content: "";
  position: absolute;
  top: -90px;
  height: 75px;
  width: 100%;
  background-color: var(--default-grey);
  opacity: 0.15;
  border-radius: 5px;
  cursor: move;
}

.filters .ant-slider-handle-2 {
  margin-right: 107px;
}

.filters .ant-slider-handle-2::before {
  content: "";
  position: absolute;
  top: -65px;
  right: 5px;
  height: 35px;
  width: 4px;
  background-color: aliceblue;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: ew-resize;
}

.analytics .btn-group,
.geospatial-filter .btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analytics .btn-group .ant-btn.active,
.geospatial-filter .ant-btn.active {
  background-color: var(--default-green);
  /* width: 4rem; */
}

.analytics .btn-group .ant-btn.deactive,
.geospatial-filter .ant-btn.deactive {
  background-color: var(--default-grey);
  /* width: 4rem; */
}
.analytics-card,
.geospatial-card {
  background-color: var(--secondary-background-color) !important;
  border-radius: 2px !important;
  margin-top: 1rem !important;
  /* padding: 12px; */
}
.analytics-feature-card {
  background-color: rgb(36, 39, 48) !important;
  border-radius: 2px !important;
  /* overflow-y: scroll; */
  height: 100%;
  border: 1px solid var(--default-grey) !important;
}

.analytics-feature-card .ant-card-head-title {
  padding: 0px !important;
}

.analytics-feature-card .ant-card-extra {
  padding: 0px !important;
}

.analytics-feature-card .ant-card-actions {
  background-color: transparent !important;
  padding: 0 8px;
  border-top: 1px solid var(--default-grey);
}

.choose-layer {
  width: "100%";
  background-color: var(--secondary-background-color) !important;
  border: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.choose-layer > .ant-dropdown-menu-item {
  flex-grow: 1;
  padding: 10px 15px;
  color: var(--default-grey) !important;
}
.choose-layer > .ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.choose-layer svg {
  font-size: 42px;
  border: 2px solid var(--default-grey);
  color: var(--default-grey);
  padding: 5px;
}

.choose-layer svg:hover {
  color: var(--default-white) !important;
  border: 2px solid var(--default-white);
}

.select-fields {
  background-color: var(--secondary-background-color) !important;
  border: 0px;
  max-height: 350px;
  /* max-width: 258px; */
  max-width: 100%;
  overflow-y: scroll;
}

.select-fields.layer {
  margin-left: 20px !important;
  width: 258px !important;
}

.select-fields.layer svg {
  color: var(--default-grey);
}

/* .select-fields.filter {
  max-width: 298px !important;
} */

.select-fields .ant-dropdown-menu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: var(--default-grey) !important;
}

.select-fields > .ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.select-fields .ant-input-affix-wrapper {
  background-color: rgb(36, 39, 48) !important;
}

.select-fields .ant-input-affix-wrapper input {
  background-color: rgb(36, 39, 48) !important;
  color: var(--default-grey) !important;
}

.select-fields-input {
  background-color: var(--secondary-background-color);
  width: 100%;
  height: 40px;
  color: var(--default-grey);
  font-size: 14px;
  cursor: pointer;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
}

.select-fields-input .ant-dropdown {
  margin-left: 36px !important;
  width: 258px !important;
}

.select-fields-input svg {
  font-size: 16px;
  margin-top: 4px;
  z-index: 1000;
}

.select-datasets {
  background-color: rgb(36, 39, 48) !important;
  text-align: left !important;
  border: 0px !important;
  width: 205px !important;
  height: 40px !important;
  color: var(--default-grey) !important;
  font-size: 14px !important;
  margin-top: -2px !important;
}

.select-datasets .ant-typography {
  color: var(--default-grey) !important;
}

.select-datasets-overlay {
  background-color: var(--secondary-background-color) !important;
  border: 0px;
  max-height: 350px;
  width: 283px;
  overflow-y: scroll;
}

.select-datasets-overlay .ant-dropdown-menu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: var(--default-grey) !important;
}

.select-datasets-overlay > .ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.select-datasets-overlay .ant-typography {
  color: var(--default-grey) !important;
}

.select-datasets-overlay .ant-input-affix-wrapper {
  background-color: rgb(36, 39, 48) !important;
}

.select-datasets-overlay .ant-input-affix-wrapper input {
  background-color: rgb(36, 39, 48) !important;
  color: var(--default-grey) !important;
}

.columnContainer .ant-typography {
  margin-top: 0px;
}

.columnTitle :nth-child(1) {
  float: left;
}
.columnTitle :nth-child(2) {
  float: right;
}

.controll .ant-drawer-body,
.controll .ant-tabs-content-holder {
  scrollbar-width: thin;
}

.controll .ant-drawer-body::-webkit-scrollbar,
.controll .ant-tabs-content-holder::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background-color: var(--default-background-color) !important;
}

.controll .ant-drawer-body::-webkit-scrollbar-thumb,
.controll .ant-tabs-content-holder::-webkit-scrollbar-thumb {
  background-color: var(--light-shade) !important;
  border-radius: 50px !important;
}

.select-fields {
  scrollbar-width: thin !important;
}

.select-fields::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background-color: var(--secondary-background-color);
}

.select-fields::-webkit-scrollbar-thumb {
  background-color: var(--default-grey) !important;
  border-radius: 50px !important;
}

.data-item {
  display: block;
}

.data-table .ant-table-body {
  scrollbar-width: thin !important;
}

.data-table tr:nth-child(2n) td {
  background-color: #f7f7f7 !important;
}

.dataset-progress .ant-progress-inner {
  height: 2px;
  margin-top: -30px;
}

.uploaded-dataset .ant-progress-inner {
  height: 2px;
  margin-top: -42px !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  bottom: 0;
  z-index: 1002;
  width: 20px;
  height: 100%;
  cursor: col-resize;
}

.react-resize-icon {
  position: absolute;
  bottom: 45vh;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--default-green);
  color: var(--default-white);
  border-radius: 50%;
  z-index: 1001;
}

.table-title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notification-list .ant-list-items {
  overflow-x: scroll !important;
  max-height: 350px !important;
}

.notification-list .ant-dropdown-menu-item:hover {
  pointer-events: none !important;
}

.object-info-popup .mapboxgl-popup-content {
  padding: 0 !important;
  cursor: pointer !important;
}

.object-info .ant-descriptions-item-content {
  padding: 4px !important;
}

.object-info .ant-card-actions > li {
  text-align: left !important;
}

.object-info .ant-descriptions-row {
  display: flex !important;
}

.report-tab {
  padding: 0px 24px 12px 24px !important;
}

.report-tab .ant-tabs-nav {
  margin: 0px 0px 12px 0px !important;
}

.report-tab .ant-tabs-tabpane {
  min-height: 80vh !important;
}

.report-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.report-tab .ant-tabs-tab.ant-tabs-tab-active svg {
  color: var(--default-grey) !important;
}

.report-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-white) !important;
}

.report-tab .ant-tabs-tab .ant-tabs-tab-btn,
.report-tab .ant-tabs-tab svg {
  color: var(--default-white) !important;
}

.report-tab .ant-tabs-tab {
  background-color: var(--default-grey) !important;
}

.colorRange-select {
  width: 50% !important;
}

.colorRange-select > .ant-select-selector {
  background-color: rgb(41, 50, 60) !important;
  color: white !important;
}
.report-charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 12px;
}
.report-card {
  min-width: 500px;
  height: min-content;
}

.report-card .ant-typography-edit {
  display: inline-block !important;
  margin-left: 24px;
  color: #000;
}

.report-card .ant-typography-edit-content-confirm {
  display: inline-block !important;
}

.dropdown-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.export-options {
  background-color: var(--secondary-background-color) !important;
}

/* .export-options .ant-select-item-option-selected,
.ant-select-item-option-active {
  background-color: var(--shade) !important;
  color: white !important;
} */

.export-options .ant-dropdown-menu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: var(--default-grey) !important;
}

.export-options > .ant-dropdown-menu-item:hover {
  background: var(--shade) !important;
  color: var(--default-white) !important;
}

.legend-items {
  width: "100%";
  white-space: nowrap;
  font-weight: 300;
  color: var(--shade);
}

.legend-items.ant-descriptions-item,
.legend-items.ant-descriptions-item {
  padding: 4px !important;
  width: 225px !important;
  overflow-x: scroll;
}

.legend-items.ant-descriptions-item-label {
  font-size: 14px !important;
  color: var(--default-grey) !important;
}

.legend-items.ant-descriptions-item-content {
  font-size: 14px !important;
  color: var(--default-grey) !important;
}

.legend-items > span {
  font-weight: 600;
  color: var(--default-background-color);
}

.categories-search,
.categories-search input {
  background-color: var(--secondary-background-color) !important;
  color: white !important;
  border-color: var(--secondary-background-color) !important;
  outline-width: 0 !important;
  box-shadow: none !important;
}

.categories-search svg {
  color: var(--default-white);
}

.categories-search .anticon-close-circle {
  margin-right: 12px;
}

.dataset-info .ant-card-head-title,
.legend .ant-card-head-title {
  padding: 7px 0 !important;
}

.dataset-info .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 7px 12px !important;
}

.dataset-info-card-header > .ant-space-item:first-child,
.legend-card-header > .ant-space-item:first-child {
  flex: 1;
}

.column-info-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 2px;
  width: 15px;
  height: 15px;
}

.column-info-btn > * {
  font-size: 12px;
}

.input-hide-geometry > span:not(.ant-checkbox) {
  padding-inline: 2px;
}

.dataset-info-paragraph > a {
  display: block !important;
}

.categories .ant-tree-iconEle > .anticon-file {
  display: none !important;
}

.categories .ant-tree-iconEle > .anticon-folder,
.categories .ant-tree-iconEle > .anticon-folder-open {
  padding: 0 4px;
}

/* access components styles */
.access-page-header {
  padding-left: 38px;
}
.access-page-header .ant-page-header-heading {
  margin-top: 0px !important;
}

.ant-page-header .ant-breadcrumb-link {
  font-weight: 500;
  font-size: medium;
}

.access-page-sider {
  flex: 0 0 20vw !important;
  max-width: 20vw !important;
  min-width: 240px !important;
  width: 20vw !important;
  background: none;
}

.access-sidebar {
  background: var(--default-white);
  height: 100vh;
  width: 20vw;
  min-width: 240px;
  row-gap: 0 !important;
}

.access-sidebar > .ant-space-item:first-child {
  height: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
}

.access-sidebar .ant-menu-item {
  color: #676767;
  height: 2.5rem;
  font-size: 1rem !important;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.access-sidebar .ant-menu-item .ant-menu-title-content {
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.access-sidebar .ant-menu-item-selected {
  background-color: #f8f8f8 !important;
  color: #262626;
  font-weight: 600;
  border-right: 4px solid var(--default-blue);
  position: relative;
}

.access-sidebar .ant-badge {
  position: absolute !important;
  top: 9px;
  left: max(20vw - 50px, 190px) !important;
}

.access-sidebar .ant-badge-count {
  border-radius: 4px !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
  font-size: 0.9rem;
  padding: 0;
}

.access-tab .ant-tabs-tabpane {
  padding: 0 !important;
}

.access .input-container,
.revoke-access-modal .input-container {
  width: 100%;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
}

.access .input-container input,
.access .input-container textarea,
.revoke-access-modal .input-container input,
.revoke-access-modal .input-container textarea {
  background: inherit;
  border: none;
  box-shadow: none;
}

/* .manage-access-table thead th {
  background: var(--default-green) !important;
  color: white !important;
} */

.manage-access-table .ant-table-thead .ant-table-cell {
  background-color: white;
}

.manage-access-table thead th:last-child {
  display: none;
}

.manage-access-table thead th:nth-last-child(2) {
  text-align: left;
}

.manage-access-table table .ant-table-cell {
  padding-inline: 1rem !important;
  font-weight: 500;
  border: none;
}

.access-breadcrumb {
  cursor: pointer;
  font-size: 22px;
}

.primary-btn,
.primary-btn:hover,
.primary-btn:focus {
  background-color: var(--default-green) !important;
  transition: all 0.4s ease 0s;
  border: none !important;
}

.manage-btn {
  background: none !important;
  line-height: 1rem !important;
  height: auto !important;
}

.permission-group > .ant-space-item:nth-child(2) {
  flex: 1;
}
.permission-group .ant-page-header-content {
  padding: 0;
}

.custom-permissions > *,
.base-permissions > * {
  flex-basis: 100%;
  height: 100%;
}

.base-permissions .info-card {
  padding: 1rem 1.5rem;
  margin-top: 4rem;
  background-color: #0f966814;
  border-top: 8px solid var(--default-blue);
  border-radius: 5px;
}

.access-picker {
  position: absolute;
  top: 0;
  right: 40px;
}

.access-picker-dropdown {
  width: 135px !important;
}

.datasets .ant-card-head-wrapper {
  height: 48px;
}

.datasets .ant-card-head-title .ant-typography,
.datasets .ant-card-extra .ant-typography {
  color: var(--default-white) !important;
}

.datasets > * {
  padding: 0.5rem 1rem;
}

.datasets-header {
  background: var(--default-green);
  display: flex;
  justify-content: space-between;
}

.datasets-header > * {
  color: var(--default-white);
  font-size: 1rem;
  font-weight: 500;
}

.datasets-header .access-picker .ant-select-selector {
  color: white !important;
}

.datasets-header .access-picker .ant-select-selection-item {
  font-weight: 500;
  font-size: 1rem;
}

.datasets-header .access-picker .ant-select-arrow {
  color: white;
}

.datasets-tree svg:not(.dataset-handling-icons svg):not(.access-picker svg) {
  font-size: 18px;
  color: var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-iconEle {
  margin: 2.5px 2.5px 0px 0px !important;
  width: auto !important;
  height: auto !important;
}

.datasets-tree .ant-tree-treenode-selected::before {
  background-color: var(--default-white) !important;
  color: var(--default-grey) !important;
}

.datasets-tree .ant-tree-treenode:hover::before {
  background-color: var(--default-white) !important;
}

.datasets-tree .ant-tree-switcher {
  background: transparent !important;
}

.datasets-tree .ant-tree-switcher-icon {
  display: none !important;
}

.datasets-tree
  .ant-tree-node-content-wrapper:not(.ant-tree-node-content-wrapper-normal) {
  margin-left: -22px !important;
}

.datasets-tree .ant-tree-node-content-wrapper {
  display: flex;
  padding: 0 !important;
  align-items: center;
  height: 38px;
}

.datasets-tree .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-switcher-leaf-line::before,
.datasets-tree .ant-tree-indent-unit::before {
  border-right: 1px solid var(--secondary-grey) !important;
}

/* access directory tree */
.datasets-tree .ant-tree-switcher-icon {
  display: none !important;
}

.datasets-tree .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid var(--secondary-grey) !important;
}
.datasets-tree .ant-tree-switcher-leaf-line::before,
.datasets-tree .ant-tree-indent-unit::before {
  border-right: 1px solid var(--secondary-grey) !important;
}

.datasets-tree .ant-tree-checkbox {
  position: absolute;
  right: 0px;
  margin-top: 8px;
  z-index: 2 !important;
}

.datasets-tree .ant-tree-checkbox-inner {
  border: 1px solid var(--default-grey);
  padding: 8px;
}

/* download progress style */
.progress-bar {
  height: 2.5px;
  margin-top: -4px;
  /* background-color: rgba(5, 114, 206, 0.2); */
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--default-blue);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 60%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.5);
  }
  100% {
    transform: translateX(100%) scaleX(1);
  }
}

/* dfes styles */
.dfes-container .heading {
  /* background-color: rgb(134, 134, 135); */
  margin-bottom: 20px;
}

.dfes-container .switch-wrapper {
  margin-block: 20px;
}

.dfes-switch {
  width: max-content;
  display: inline-flex;
  border: 1px solid var(--default-grey);
  border-radius: 9999px;
  box-shadow: 0px 1px 2px #00000026;
}

.dfes-switch input[type="radio"] {
  display: none;
}

.dfes-switch input[type="radio"]:checked + label {
  background-color: var(--default-green);
  color: white;
}

.dfes-switch label {
  color: var(--default-grey);
  padding: 2px 16px;
  border-radius: 9999px;
  cursor: pointer;
}

.dfes-container .dropdown-wrapper,
.geospatial-filter .dropdown-wrapper {
  margin-block: 12px;
}

.dfes-dropdown-overlay {
  opacity: 0.3;
}

/* .dfes-dropdown-overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
} */

.ant-select.dfes-dropdown,
.ant-select.geospatial-dropdown {
  display: block;
  width: 100%;
  margin-block: 10px;
  background-color: var(--secondary-background-color);
  color: white !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}
.ant-select.geospatial-dropdown {
  background-color: var(--default-background-color);
}

.dfes-dropdown .ant-select-arrow,
.geospatial-dropdown .ant-select-arrow {
  color: var(--default-grey) !important;
}

.dfes-dropdown .ant-select-dropdown {
  background-color: var(--secondary-background-color) !important;
}

.geospatial-dropdown .ant-select-dropdown {
  background-color: var(--default-background-color) !important;
}

.dfes-dropdown .ant-select-item,
.geospatial-dropdown .ant-select-item {
  color: rgba(255, 255, 255, 0.65) !important;
}

.dfes-dropdown .ant-select-item-option-selected,
.dfes-dropdown .ant-select-item-option-active,
.geospatial-dropdown .ant-select-item-option-selected,
.geospatial-dropdown .ant-select-item-option-active {
  background-color: var(--shade) !important;
  color: white !important;
}

.geospatial-dropdown .ant-select-selector {
  width: 100%;
}

.geospatial-dropdown.ant-select-multiple .ant-select-selection-item {
  background-color: var(--secondary-background-color);
  border-color: var(--secondary-background-color);
}

.geospatial-dropdown.ant-select-multiple .ant-select-selection-item-remove {
  color: var(--default-white);
}
.dfes-timeseries {
  position: absolute !important;
  background-color: var(--default-background-color) !important;
  bottom: 0px;
  height: 200px;
  /* width: calc(100% - 390px) !important; */
}

.dfes-timeseries-slider .ant-slider-step {
  background-color: var(--light-shade) !important;
  border-radius: 0px !important;
  height: 22px;
}

.dfes-timeseries-slider .ant-slider-track {
  background-color: rgb(18, 147, 154) !important;
  border: 0px !important;
  border-radius: 0px !important;
  margin-top: 8px !important;
  height: 6px;
}

.dfes-timeseries-slider .ant-slider:hover .ant-slider-step {
  background-color: var(--light-shade) !important;
}

.dfes-timeseries-slider .ant-slider-handle {
  margin-top: -1.8px;
  height: 26px;
  width: 8px;
  border-radius: 0px;
  border: none;
}

.dfes-timeseries-slider .ant-slider-dot-active {
  background-color: rgb(18, 147, 154) !important;
  margin-top: 2px !important;
  height: 22px !important;
}

.dfes-timeseries-slider .ant-slider-rail {
  background-color: var(--default-grey) !important;
  margin-top: 8px !important;
  height: 6px;
}

.dfes-timeseries-slider .ant-slider-dot {
  background-color: var(--default-white);
  height: 22px;
  border: none;
  width: 2px;
  border-radius: 0px;
  margin-top: 2.2px;
}

.dfes-timeseries-slider {
  width: 90%;
  margin: 0;
}

.dfes-timeseries-slider .ant-tooltip-inner {
  background-color: var(--default-white) !important;
  color: var(--default-black) !important;
  font-size: 18px;
  font-weight: 600;
  padding: 0px 4px;
  display: flex;
  align-items: center;
}

.dfes-timeseries-slider .ant-tooltip-arrow-content {
  background-color: var(--default-white) !important;
}

.dfes-speed-controls .ant-dropdown-menu-item:hover {
  background-color: var(--secondary-background-color) !important;
}

.dfes-icon {
  color: var(--default-grey);
  font-size: 14px;
}

.dfes-icon:hover,
.ant-tabs-tab-active .dfes-icon {
  color: var(--default-white);
}

.assets-guide .ant-timeline-item-head,
.assets-analysis .fix .ant-timeline-item-head {
  background-color: var(--default-green) !important;
  border: 1px solid var(--default-green);
}

.assets-analysis .warning .ant-timeline-item-head {
  background-color: orange !important;
  border: 1px solid orange;
}

.assets-analysis .error .ant-timeline-item-head {
  background-color: var(--default-red) !important;
  border: 1px solid var(--default-red);
}

.assets-guide .ant-timeline-item-tail,
.assets-analysis .ant-timeline-item-tail {
  border-left: 2px solid var(--default-grey);
}

.analytics-guide .ant-timeline-item-head,
.assets-analysis .fix .ant-timeline-item-head {
  background-color: #c5cfcb !important;
  border: 1px solid var(--default-green);
  border-width: 3px;
}

.analytics-guide .ant-timeline-item-tail,
.assets-analysis .ant-timeline-item-tail {
  border-left: 2px solid var(--default-grey);
}

.assets .ant-descriptions-bordered .ant-descriptions-view,
.assets .ant-descriptions-bordered .ant-descriptions-row,
.assets .ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border: none !important;
}

.assets .ant-empty-img-simple-ellipse {
  fill: var(--light-shade);
}

.assets .ant-empty-img-simple-path {
  fill: var(--default-background-color);
}

.assets-header .ant-page-header-heading {
  align-items: center;
}

.assets-header .ant-page-header-heading-left {
  margin: 0px;
}

.assets .ant-popover-inner-content {
  padding: 8px;
}

.assets-wrapper > .ant-tabs-nav {
  display: none !important;
}

.assets-wrapper > .ant-tabs-content-holder {
  padding: 0px !important;
}

.assets .project-title textarea {
  background-color: var(--default-background-color);
  color: var(--default-white);
  margin-left: 12px;
}

/* .kw-selector {
  width: 100%;
}
.kw-selector .ant-select-arrow {
  color: var(--light-text);
}
.kw-selector.ant-select .ant-select-selector {
  background-color: var(--shade);
  border: none;
  color: var(--light-text);
} */

.asset-properties p,
.asset-properties .ant-typography,
.asset-properties h3 {
  color: var(--light-text) !important;
}

.asset-properties .ant-card-body,
.asset-properties .ant-card-head,
.asset-properties .ant-card-actions {
  background-color: var(--secondary-background-color) !important;
}

.asset-properties .ant-card-head {
  min-height: 34px !important;
  padding: 0px 12px;
  display: flex;
  align-items: center;
}

.asset-properties .ant-card-head-title {
  padding: 0px;
  color: var(--light-text);
}

.asset-properties .ant-card-actions {
  border: none;
}

.asset-properties .ant-card-actions > li {
  margin: 0px;
  padding: 0px 12px 8px 12px !important;
}

.asset-properties .ant-radio-group {
  display: flex !important;
  gap: 12px;
}

.asset-properties .ant-radio-button-wrapper {
  background-color: var(--shade);
  color: var(--light-text);
  border: none !important;
}

.asset-properties .ant-radio-button-wrapper::before {
  background-color: transparent !important;
}

.asset-properties .ant-radio-button-wrapper-checked {
  background-color: var(--default-background-color) !important;
  font-weight: 600;
}

.asset-properties .ant-radio-button .ant-radio-button-checked:focus-visible {
  border: none !important;
  outline: none !important;
}

.asset-properties .ant-radio-button-wrapper-checked:hover::before {
  border-color: transparent !important;
}

.asset-properties .ant-slider-track {
  background-color: var(--default-green) !important;
}

.asset-properties .ant-slider-dot,
.asset-properties .ant-slider-dot-active {
  display: none !important;
}

.asset-properties .ant-slider-handle {
  border-radius: 0px;
  outline: none;
  border: none;
  width: 8px;
}

.asset-properties .ant-slider-rail {
  height: 2px;
  margin-top: 1px;
}

.asset-slider .ant-tooltip-placement-top {
  padding-bottom: 4px !important;
}

.asset-slider .ant-tooltip-inner {
  padding: 2px 6px !important;
  font-size: 11px;
  min-height: 24px;
  background-color: var(--default-background-color);
}

.building-info .ant-popover-inner-content {
  padding: 8px;
  max-height: 70vh;
  overflow-y: scroll;
}

.building-info .ant-collapse-content {
  max-height: 350px !important;
  overflow-y: scroll !important;
}

.building-info .ant-collapse-content-box {
  padding: 12px 8px;
}

.building-wrapper .ant-collapse-header {
  padding: 8px !important;
}

.building-address .ant-collapse-header {
  padding: 12px 0px !important;
}
.domestic-dropdown .domestic-asset-option {
  padding: 4px;
}

.domestic-dropdown .domestic-asset-option:hover {
  background-color: var(--light-shade) !important;
}

.data-table .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

/* New reports */

.reports-page-header .ant-page-header-heading-left {
  flex-direction: column;
  align-items: start !important;
}

.reports-page-header .ant-page-header-heading-sub-title {
  width: 550px;
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: unset !important;
}

.reports-page-sider {
  min-width: 330px !important;
}

.reports-sidebar {
  background: var(--default-background-color);
  width: 100%;
  height: 100%;
}

.reports-search-input {
  background: var(--secondary-background-color);
  color: white;
  margin: 14px;
  height: 40px;
  padding-left: 5px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.reports-search-input .ant-input {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--default-white) !important;
}

.reports-menu-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px !important;
  height: 100%;
  position: relative;
  transition: 300ms all ease;
}

.reports-sidebar .ant-menu-item-selected .reports-menu-label,
.reports-sidebar .ant-menu-item-active .reports-menu-label {
  background: var(--secondary-background-color) !important;
}

.reports-sidebar .ant-menu-item .selected {
  display: none;
  transition: 300ms all ease;
}

.reports-sidebar .ant-menu-item-selected .selected {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: var(--default-green);
}

.report-share-options .ant-dropdown-menu-item,
.report-edit-options .ant-dropdown-menu-item {
  font-size: 14px;
  color: var(--default-black) !important;
}

.report-share-options,
.report-edit-options {
  background-color: var(--default-white) !important;
}

.report-share-options > .ant-dropdown-menu-item:hover,
.report-edit-options > .ant-dropdown-menu-item:hover {
  background: rgba(7, 149, 43, 0.08) !important;
  color: var(--default-lightgrey) !important;
}

.report-share-modal .ant-form-item {
  margin-bottom: 0 !important;
}

.ant-space.full-width {
  width: 100%;
}

.ant-space.full-width > * {
  flex: 1 !important;
}

/* stories styles */

.data-stories-header .ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.sun-editor button {
  color: var(--default-grey) !important;
}

.sun-editor .se-svg,
.sun-editor button > svg {
  width: 14px !important;
  height: 14px !important;
}

.story-section {
  height: 93vh !important;
}

.story-nav {
  height: 8vh;
  width: 100%;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.story-section .slick-dots li {
  background-color: var(--light-shade);
}

.story-section .slick-dots li.slick-active button {
  background-color: #0f9668 !important;
}

.story-section .slick-track {
  height: 100% !important;
}

.story {
  height: 85vh;
  padding: 16px 24px;
  background-color: white;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  scroll-snap-align: start;
  user-select: none;
}

.story-content {
  text-align: left;
  padding: 2px;
  overflow-y: scroll;
}

.media-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-section .ant-tabs-nav {
  display: none !important;
}

.media-section .ant-tabs-content-holder {
  padding: 16px 24px;
}

/* stories editor styles */

.stories-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  height: 100vh;
}

.stories-editor .ant-card-head-wrapper {
  align-items: flex-start;
}

.stories-editor .ant-page-header {
  background-color: var(--default-white);
}

.stories-editor-content {
  overflow-y: scroll;
  width: 100%;
  padding: 12px;
}

.stories-editor-content .story-media-card {
  min-height: 65.5vh;
}

.stories-editor-content .story-media-card .ant-card-head {
  min-height: 41.5px !important;
  padding: 0px 16px;
}

.stories-editor-content .story-media-card .ant-card-head-title {
  padding: 8px 0px;
}

.story-category
  .ant-tree.ant-tree-directory.ant-tree-treenode-selected:hover::before,
.story-category
  .ant-tree.ant-tree-directory
  .ant-tree-treenode-selected::before {
  background-color: var(--default-green) !important;
}

.story-category .ant-tree-treenode-selected .ant-typography {
  color: var(--default-white) !important;
}
.object-info .ant-descriptions-row {
  display: flex !important;
}

/* Carbon panel styles */
.carbon-drawer .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.carbon-expand {
  position: absolute !important;
  border: 0 !important;
  background-color: var(--default-background-color) !important;
  color: white !important;
  top: 5px;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  border-left: 1px solid rgb(218, 220, 224);
  /* border-radius: 8px 0 0 8px !important; */
  background: #fff 7px center/7px 10px no-repeat;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

.carbonpanel-tabs .ant-tabs-nav-operations {
  display: none !important;
}

.carbon-description .ant-descriptions-header {
  margin: 20px 0 0 0;
  background-color: #665c5c2e;
  padding-left: 15px;
}

.carbon-description .ant-descriptions-view {
  height: 80px;
  width: 100%;
  overflow-y: scroll;
}

.Carbon-table .ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px 50px 10px 35px;
  border: 1px solid rgb(243, 238, 238);
}

.Carbon-asset-table .ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 10px 20px;
}

/* multiple maps */

.ant-card.display-entity-card {
  display: flex;
  flex-direction: column;
}

.display-entity-card .ant-card-body {
  flex: 1;
}

.display-entity-card .ant-card-meta-detail {
  width: 100% !important;
}

.display-entity-card .ant-card-meta-title {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-bottom: 4px !important;
}

.builder-query-modal .ant-modal-body {
  padding: 0;
}

.analytics-page-drawer > .ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.query-builder-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px !important;
  padding-bottom: 8px !important;
}

.query-builder-collapse
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-left: 5px !important;
}

.data-table-analytics .ant-table-thead > tr > th {
  font-size: 14px !important;
  text-transform: capitalize;
}

.analytics-progress-health
  > .ant-progress-outer
  > .ant-progress-inner
  > .ant-progress-bg {
  height: 15px !important;
  border-radius: unset;
  background-color: #f6b26b;
}

.analytics-progress-health > .ant-progress-outer > .ant-progress-inner {
  border-radius: unset !important;
}

.analytics-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--default-green);
}

.analytics-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: var(--default-green);
}

.analytics-steps .ant-steps-item-title {
  font-size: 14px;
}

.analytics-steps .ant-steps-item-content {
  min-height: 40px !important;
}

.queryform-conditionInputValue .ant-row {
  align-items: center;
}

.dashboard-page-header .ant-page-header-heading {
  justify-content: flex-start;
}

.analytics-question-card .ant-card-body {
  padding: 15px !important;
}


.analytics-query-slider .ant-slider-mark-text {
  text-overflow: ellipsis;
  width: 56px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}

/* Access page classes */

.access-tabs .ant-tabs-nav-list {
  color: white;
  margin-left: 41px;
  margin-top: 20px;
}
.access-tabs .ant-tabs-tab {
  border-right: 1px solid white;
  padding: 10px 30px;
  margin: 0 !important;
  background-color: #1f1b24;
}
.access-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.access-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1b24 !important;
}
.access-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: white !important;
}
.access-tabs .ant-tabs-tab:hover {
  color: unset;
}
.access-tabs .ant-tabs-ink-bar {
  background: none !important;
}