:root {
  --layout-header-footer-background-color: #f0f2f5;
  --layout-header-height: 10vh;
  --layout-footer-height: 8vh;
  --page-height: 100vh;
  --page-width: 100vw;
  --layout-sider-width: 250px;
  --layout-sider-background-color: rgb(36, 39, 48);
  --layout-sider-newbackground-color: #1E1B23;
  --layout-content-height: 80vh;
  --layout-content-width: 100%;
  --layout-content-background-color: #f0f2f5;
  --layout-menu-selected-background-color: rgb(41, 50, 60);
  --default-grey: rgb(106, 116, 133);
  /* --trigger-svg-color: #000; */
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey !important;
  border-radius: 50px !important;
}

.login-container {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-overlay {
  background: #f0f2f5;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.login-main {
  position: absolute;
  top: 0;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-card,
.signup-card,
.forgot-password-card {
  width: 450px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.sider {
  height: var(--page-height);
  /* width: 230px !important;
  min-width: 230px !important;
  max-width: 230px !important; */
  /* background: var(--layout-sider-background-color) !important; */
  background: var(--layout-sider-newbackground-color) !important;
}

.sider .ant-menu,
.footer .ant-menu {
  /* background: var(--layout-sider-background-color) !important; */
  background: var(--layout-sider-newbackground-color) !important;
}

.sider .ant-layout-sider-trigger {
  background: var(--layout-menu-selected-background-color) !important;
}

.sider .ant-layout-sider-trigger svg {
  margin-top: 18px;
}

.sider .ant-menu.top-menu {
  /* height: 78.5vh !important; */
  /* height: 78.5vh !important; */
  position: absolute !important;
}

.sider .ant-menu.top-menu {
  /* height: 78.5vh !important; */
  /* height: 78.5vh !important; */
  position: absolute !important;
}

.sider .bottom-menu {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 75px;
  width: 100%;
}

.sider .bottom-menu button:hover {
  color: var(--default-white) !important;
}

.sider .bottom-menu button {
  color: rgba(255, 255, 255, 0.65);
}

.sider .user-control,
.sider .user-help,
.sider .dataset-methodologies {
  background-color: var(--layout-sider-background-color) !important;
  position: absolute !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 75px;
  width: 100%;
}

.sider .bottom-menu button:hover {
  color: var(--default-white) !important;
}

.sider .bottom-menu button {
  color: rgba(255, 255, 255, 0.65);
}

.sider .user-control:hover,
.sider .user-help:hover,
.sider .dataset-methodologies:hover {
  color: var(--default-white) !important;
}

.sider .ant-menu-item-selected:not(.user-control, .user-help),
.sider .ant-menu-item-active {
  background: var(--layout-menu-selected-background-color) !important;
  /* border-left: 5px solid rgb(106, 116, 133); */
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.sider .ant-menu-item-selected svg:not(.user-control svg, .user-help svg) {
  margin-left: -2px;
  font-size: 1.2rem;
}

.header {
  width: 100%;
  height: 10vh;
  background: var(--layout-header-footer-background-color) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 12px !important;
}

.header .anticon-menu-unfold,
.anticon-menu-fold {
  font-size: 24px;
  cursor: pointer;
}

.content {
  overflow: hidden;
  position: relative !important;
  width: 100%;
  height: 100%;
  background: var(--layout-content-background-color);
}

.controll .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.ant-tabs-tab-active svg {
  color: white !important;
}

.upload-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-grey) !important;
  border-color: var(--default-grey) !important;
}

.upload-checkbox .ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: 0 !important;
}

.upload-checkbox .ant-checkbox .ant-checkbox-inner {
  background-color: var(--layout-sider-background-color);
  border-color: var(--default-grey) !important;
}

.ant-checkbox-wrapper::after {
  display: none !important;
}

.controll .ant-tabs-ink-bar {
  position: absolute;
  background: white !important;
  pointer-events: none;
}

.footer {
  width: 100%;
  height: var(--layout-footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--layout-header-footer-background-color) !important;
}

.footer .ant-menu-item-selected {
  background: var(--layout-menu-selected-background-color) !important;
  border-bottom: 5px solid rgb(106, 116, 133) !important;
}

.logo {
  /* height: 32px; */
  margin: 16px;
  animation: logo-fadeIn 0.5s linear;
}
.icon {
  /* height: 32px; */
  /* height: 100px; */
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: icon-fadeIn 0.5s linear;
}

.logo-icon {
  display: none;
}

.ant-layout-sider-trigger {
  /* background: var(--layout-trigger-background-color) !important; */
  height: 8vh !important;
}

.ant-typography-edit,
.ant-typography-edit-content-confirm {
  display: none !important;
}
.ant-descriptions-item-content textarea {
  margin-left: 12px !important;
}

.reports {
  height: 100vh;
  overflow-y: scroll;
}

@keyframes logo-fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes icon-fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media screen and (max-width: 640px) {
  .footer-text,
  .menu-control,
  .sider {
    display: none !important;
  }
  .content {
    overflow-y: scroll;
  }
  .mobile-menu {
    display: block !important;
    width: 100%;
  }
  .footer {
    padding: 12px !important;
    height: auto;
  }
  .profile-card .ant-card-body {
    height: 100% !important;
  }
  .profile {
    grid-template-columns: 100% !important;
    grid-template-rows: 2 !important;
  }
  .expand-controls {
    top: 73vh !important;
    transform: rotate(90deg);
  }
  .logo-icon {
    display: block;
    block-size: 32px;
  }
}

.auth-container {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 580px;
  border-radius: 12px !important;
  margin-bottom: 20px !important;
}

.auth-container .ant-card-head-title {
  padding-bottom: 0px !important;
}

.auth-container
  .ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon,
.auth-container
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--default-green);
}

.auth-container .ant-steps-navigation .ant-steps-item::before {
  background-color: var(--default-green);
}

#phone-input::-webkit-outer-spin-button,
#phone-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#phone-input[type=number] {
  -moz-appearance: textfield;
}

/* Guidance changes */

.guidance-tabs .ant-tabs-nav-list {
  color: white;
  margin-left: 100px;
  margin-top: 20px;
}
.guidance-tabs .ant-tabs-tab {
  border-right: 1px solid white;
  padding: 10px 30px;
  margin: 0 !important;
  background-color: #1f1b24;
}
.guidance-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}
.guidance-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1b24 !important;
}
.guidance-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: white !important;
}
.guidance-tabs .ant-tabs-tab:hover {
  color: unset;
}
.guidance-tabs .ant-tabs-ink-bar {
  background: none !important;
}

.guidance-select .ant-select-selector {
  background-color: #dbe0ec !important;
  border-color: #6a7485 !important;
  border-radius: 4px !important;
  height: 35px !important;
  align-items: center !important;
}

.carbonpanel-tabs .ant-tabs-tab {
  border-right: 1px solid white;
  padding: 10px 23.3px;
  margin: 0 !important;
  background-color: #1f1b24;
  color: white;
}

.carbon-drawer .ant-drawer-body {
  padding: 0 !important;
}

.carbonpanel-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.carbonpanel-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: black !important;
}

.carbonpanel-tabs .ant-tabs-ink-bar {
  background: none !important;
}

.top-menu .ant-menu-item {
  padding-left: 40px !important;
  gap: 5px !important;
}